//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    tag: {
      default: "button"
    },
    variant: {
      type: String,
      validate: v => ["primary", "secondary", "outlined"].includes(v),
      default: "primary"
    },
    block: {
      type: Boolean,
      default: false
    }
  }
};