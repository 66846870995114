//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Visa from "@/components/Billing/icons/visa.svg?inline";
import Mastercard from "@/components/Billing/icons/mastercard.svg?inline";
import Mir from "@/components/Billing/icons/mir.svg?inline";
import Unionpay from "@/components/Billing/icons/unionpay.svg?inline";
//import Qiwi from "@/components/Billing/icons/qiwi.svg?inline";
import Yoomoney from "@/components/Billing/icons/yoomoney.svg?inline";
import Invoice from "@/components/Billing/icons/invoice.svg?inline";
import Wechat from "@/components/Billing/icons/wechat3.svg?inline";
export default {
  components: {
    Wechat,
    Visa,
    Mastercard,
    Mir,
    Unionpay,
    //Qiwi,
    Yoomoney,
    Invoice
  }
};