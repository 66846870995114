//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DrawerMenu from "./DrawerMenu";
import Logo from "./common/P/Logo";
import Auth from "./common/P/Auth";
export default {
  data() {
    return {
      drawerMenuIsOpen: false,
      menuItems: [{
        link: "/features",
        text: "Возможности сервиса"
      }, {
        link: "/pricing",
        text: "Тарифы"
      }, {
        link: "/contact",
        text: "Контакты"
      }, {
        link: "https://help.salesfinder.ru/salesfinder-plugin",
        text: "Плагин для браузера"
      }]
    };
  },
  created() {
    this.$store.dispatch("user/getUser");
  },
  methods: {
    closeDrawerMenu() {
      this.drawerMenuIsOpen = false;
    },
    openDrawerMenu() {
      this.drawerMenuIsOpen = true;
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  components: {
    DrawerMenu,
    Logo,
    Auth
  }
};